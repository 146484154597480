import axios from 'axios'
import store from "./store"
import { makeTimestamp } from './helper/helper';

export default class RESTService {

    constructor() {
        axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT_BASEURL;
        axios.defaults.timeout = 15000; // requests must be serviced within 15 seconds

        // Add a request interceptor to add the token
        axios.interceptors.request.use(function (config) {
            // Add authorization header before the request is sent

            //console.log("enter request-intercepting: url=" + config?.url);
            //console.log("store accesstoken: " + store.getters.accesstoken );

            if (config?.userefreshtoken === true)
            {
                // use refresh token
                if (store.getters.refreshtoken?.length > 0)
                {
                    config.headers["Authorization"] = "Bearer " + store.getters.refreshtoken;
                }
                else {
                    //console.log("call to " + config?.url + " without token");
                }
            }
            else
            {
                // use access token
                if (store.getters.accesstoken?.length > 0)
                {
                    config.headers["Authorization"] = "Bearer " + store.getters.accesstoken;
                }
                else {
                    //console.log("call to " + config?.url + " without token");
                }
            }

            //console.log("exit request interceptingurl=" + config?.url);
            return config;
        }, function (error) {
            console.warn("ERROR in request: " + JSON.stringify(error));
            return Promise.reject(error); // Do something with request error
        });

        // Add a response interceptor
        axios.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error

            //console.warn("RESP ERROR: " + JSON.stringify(error));

            if (typeof error.response === 'undefined') {
                console.error('A network error occurred. This could be a CORS issue or a dropped internet connection.');
                error["response"] = {status: 0, data:"A network error or a CORS issue.", headers:""}
            }

            if (error?.response?.status == 401)
            {
                //let url = error.response?.config?.url ?? "";
                //console.warn("Upps, we've got 401-Unauthorized ("+url+"). Must log in again");
                store.commit("settokens", null); // not authorized - uups, invalid token -> log in again
            }

            return Promise.reject(error);
        });
    }
    async login(username, password, otp) {
        let reqPayload = {username:username, password:password, otpcode:otp};
        let resp = await axios.post(process.env.VUE_APP_API_ENDPOINT_AUTHORIZE, reqPayload);
        return resp.data;
    }

    async refreshToken() {
        let resp = await axios.get('token', {userefreshtoken: true});
        return resp.data;
    }

    async getProfile() {
        let resp = await axios.get('profile');
        return resp.data;
    }

    async getFleetDetails(fleetkey) {
        let resp = await axios.get('fleet/'+fleetkey);
        return resp.data;
    }

    async getFleetRegions(fleetkey) {
        let resp = await axios.get('fleet/'+fleetkey+'/regions');
        return resp.data;
    }

    async getFleetGroups(fleetkey) {
        let resp = await axios.get('fleet/'+fleetkey+'/groups');
        return resp.data;
    }

    async getFleetPlans(fleetkey) {
        let resp = await axios.get('fleet/'+fleetkey+'/plans');
        return resp.data;
    }

    async getFleetUsers(fleetkey) {
        let resp = await axios.get('fleet/'+fleetkey+'/users');
        return resp.data;
    }

    async getBundleDetails(bundlekey, fleetkey) {
        let resp = await axios.get('fleet/'+fleetkey+'/bundle/'+bundlekey);
        return resp.data;
    }

    async deleteBundle(bundlekey, fleetkey) {
        let resp = await axios.delete('fleet/'+fleetkey+'/bundle/'+bundlekey);
        return resp.data;
    }

    async downloadBundle(bundlekey, fleetkey, downloadProgress) {
        let resp = await axios.get('fleet/'+fleetkey+'/bundle/'+bundlekey+'/download', { responseType: 'blob', timeout: 180000, onDownloadProgress: downloadProgress });
        return resp; // return the response, not just the data
    }

    async downloadBundleImg(bundlekey, fleetkey, downloadProgress2) {
        let resp = await axios.get('fleet/'+fleetkey+'/bundle/'+bundlekey+'/downloadimg', { responseType: 'blob', timeout: 180000, onDownloadProgress: downloadProgress2 });
        return resp; // return the response, not just the data
    }

    async triggerBundleImgGeneration(bundlekey, fleetkey) {
        let resp = await axios.post('fleet/'+fleetkey+'/bundle/'+bundlekey+'/createimg');
        return resp.data;
    }

    async saveBundleChanges(bundlekey, fleetkey, releaseDate, supportedHWArray, signatureString, comment) {
        let reqData = {trelease: makeTimestamp(releaseDate), supported_hw_versions: supportedHWArray, signature: signatureString, comment: comment};
        let resp = await axios.post('fleet/'+fleetkey+'/bundle/'+bundlekey+'/modify', reqData);
        return resp.data;
    }

    async createFleet(label) {
        let reqData = {label: label};
        let resp = await axios.post('fleets/new', reqData);
        return resp.data;
    }

    async deleteFleet(fleetkey) {
        let resp = await axios.delete('fleet/'+fleetkey);
        return resp.data;
    }

    async renameFleet(fleetkey, label) {
        let reqData = {label: label};
        let resp = await axios.post('fleet/'+fleetkey+'/rename', reqData);
        return resp.data;
    }

    async downloadFleetPicture(fleetkey) {
        let resp = await axios.get('fleet/'+fleetkey+'/picture', { responseType: 'blob', timeout: 60000 });
        return resp; // return the response, not just the data
    }

    async createRegion(fleetkey, label) {
        let reqData = {label: label};
        let resp = await axios.post('fleet/'+fleetkey+"/newregion", reqData);
        return resp.data;
    }

    async deleteRegion(fleetkey, regionkey) {
        let resp = await axios.delete('fleet/'+fleetkey+'/region/'+regionkey);
        return resp.data;
    }

    async renameRegion(fleetkey, regionkey, label) {
        let reqData = {label: label};
        let resp = await axios.post('fleet/'+fleetkey+'/region/'+regionkey+'/rename', reqData);
        return resp.data;
    }

    async createGroup(fleetkey, label) {
        let reqData = {label: label};
        let resp = await axios.post('fleet/'+fleetkey+"/newgroup", reqData);
        return resp.data;
    }

    async deleteGroup(fleetkey, groupkey) {
        let resp = await axios.delete('fleet/'+fleetkey+'/group/'+groupkey);
        return resp.data;
    }

    async renameGroup(fleetkey, groupkey, label) {
        let reqData = {label: label};
        let resp = await axios.post('fleet/'+fleetkey+'/group/'+groupkey+'/rename', reqData);
        return resp.data;
    }

    async createDeploymentPlan(fleetkey, plan) {
        let reqData = { 
            label: plan.label,
            teffective: makeTimestamp(plan.teffective),
            tcompletebefore:makeTimestamp(plan.tcompletebefore),
            bundlekey:plan.bundle?.pkey ?? 0,
            maxupdates: plan.maxupdates,
            maxcoveragerate: plan.maxcoveragerate,
            regionkeys: [],
            groupkeys:[],
            constr_installedversion: plan.constr_installedversion,
            priority: parseInt(plan.priority),
        };

        for (let i = 0; i < plan.regions.length; ++i) {
            let k = (plan.regions[i].pkey ?? 0);
            if (k > 0) reqData.regionkeys.push(k);
        }

        for (let i = 0; i < plan.groups.length; ++i) {
            let k = (plan.groups[i].pkey ?? 0);
            if (k > 0) reqData.groupkeys.push(k);
        }

        let resp = await axios.post('fleet/'+fleetkey+"/newplan", reqData);
        return resp.data;
    }

    async updateDeploymentPlan(plan) {
        let reqData = { 
            label: plan.label,
            teffective: makeTimestamp(plan.teffective),
            tcompletebefore:makeTimestamp(plan.tcompletebefore),
            bundlekey:plan.bundle?.pkey ?? 0,
            maxupdates: plan.maxupdates,
            maxcoveragerate: plan.maxcoveragerate,
            regionkeys: [],
            groupkeys:[],
            constr_installedversion: plan.constr_installedversion,
            priority: parseInt(plan.priority),
        };

        for (let i = 0; i < plan.regions.length; ++i) {
            let k = (plan.regions[i].pkey ?? 0);
            if (k > 0) reqData.regionkeys.push(k);
        }

        for (let i = 0; i < plan.groups.length; ++i) {
            let k = (plan.groups[i].pkey ?? 0);
            if (k > 0) reqData.groupkeys.push(k);
        }

        let resp = await axios.post('fleet/'+plan.fleetkey+"/plan/"+plan.pkey, reqData);
        return resp.data;
    }

    async deleteDeploymentPlan(fleetkey, plankey) {
        let resp = await axios.delete('fleet/'+fleetkey+'/plan/'+plankey);
        return resp.data;
    }

    async enableDeploymentPlan(fleetkey, plankey, bActivate) {
        let resp = await axios.post('fleet/'+fleetkey+'/plan/'+plankey+'/'+(bActivate ? 'activate' : 'deactivate'));
        return resp.data;
    }

    async getDeploymentPlanDetails(plankey, fleetkey) {
        let resp = await axios.get('fleet/'+fleetkey+'/plan/'+plankey);
        return resp.data;
    }

    async queryDevicesByFleet(lazyParams, fleetkey) {
        let resp = await axios.post('fleet/'+fleetkey+'/querydevices', lazyParams, { timeout: 15000 });
        return resp.data;
    }

    async exportFleetDevicesToCSV(fleetkey, lazyParams) {
        let resp = await axios.post('fleet/'+fleetkey+'/csvexportdevices', lazyParams, { responseType: 'blob', timeout: 40000 } );
        return resp;
    }

    async exportAccountDevicesToCSV(lazyParams = {}) {
        let resp = await axios.post('account/csvexportdevices', lazyParams, { responseType: 'blob', timeout: 45000 } );
        return resp;
    }

    async changeDevicesRegion(fleetkey, deviceskeys, regionkey) {
        let reqData = {devices: deviceskeys, regionkey: regionkey};
        let resp = await axios.post('fleet/'+fleetkey+'/devices/setregion', reqData);
        return resp.data;
    }

    async changeDevicesGroup(fleetkey, deviceskeys, groupkey) {
        let reqData = {devices: deviceskeys, groupkey: groupkey};
        let resp = await axios.post('fleet/'+fleetkey+'/devices/setgroup', reqData);
        return resp.data;
    }

    async changeDevicesFleet(currentfleetkey, deviceskeys, targetfleetkey) {
        let reqData = {devices: deviceskeys, targetfleetkey: targetfleetkey};
        let resp = await axios.post('fleet/'+currentfleetkey+'/devices/setfleet', reqData);
        return resp.data;
    }

    async queryNumberOfDevicesInGroupOrRegion(fleetkey, arrayOfGroups, arrayOfRegions) {
        const groupPkeys = arrayOfGroups ? arrayOfGroups.map(a => a.pkey) : [];
        const regionPkeys = arrayOfRegions ? arrayOfRegions.map(a => a.pkey) : [];
        const reqData = {groups: groupPkeys, regions: regionPkeys};
        const resp = await axios.post('fleet/'+fleetkey+'/devices/count', reqData);
        return resp.data;
    }

    async getAccountUsers() {
        let resp = await axios.get('users');
        return resp.data;
    }

    async createAccountUser(reqData) {
        let resp = await axios.post('users/new', reqData);
        return resp.data;
    }

    async deleteAccountUser(userpkey) {
        let resp = await axios.delete('user/'+userpkey);
        return resp.data;
    }    

    async modifyAccountUser(userpkey, reqData) {
        let resp = await axios.post('user/'+userpkey+'/modify', reqData);
        return resp.data;
    }

    async activateAccessAccountUser(userpkey) {
        let resp = await axios.post('user/'+userpkey+'/unblockaccess');
        return resp.data;
    }

    async deactivateAccessAccountUser(userpkey) {
        let resp = await axios.post('user/'+userpkey+'/blockaccess');
        return resp.data;
    }

    async resetAccountUserPassword(userpkey) {
        let resp = await axios.post('user/'+userpkey+'/resetpassword');
        return resp.data;
    }

    async verifyPasswordResetSecret(secret) {
        let reqData = {secret:secret};
        let resp = await axios.post('account/verifypwreset', reqData);
        return resp.data;
    }

    async resetPassword(username, pass, pass_reset_code, otpsecret, otpcode) {
        let reqData = {username: username, password: pass, secret: pass_reset_code, totpsecret: otpsecret, otpcode: otpcode };
        let resp = await axios.post('account/pwreset', reqData);
        return resp.data;
    }

    async modifyFleetAccessOfUser(userpkey, fleetkey, access_flags) {
        let reqData = {fleetkey: fleetkey, access_flags: access_flags };
        let resp = await axios.post('user/'+userpkey+'/fleetaccess', reqData);
        return resp.data;
    }

    // Assign the fleet to any device without a fleet in this account
    async useFleetForUnassignedDevices(fleetkey) {
        let resp = await axios.post('fleet/'+fleetkey+'/usefordevices');
        return resp.data;
    }

    async getDeviceDetails(devicekey) {
        let resp = await axios.get('device/'+devicekey+"/details");
        return resp.data;
    }

    async postModifyDeviceSetting(devicekey, settingid, content) { // content can be string, object or null
        let reqData = {id:settingid,content:content};
        let resp = await axios.post('device/'+devicekey+"/modify", reqData);
        return resp.data;
    }

    async postMassConfigure(fleetkey, reqData) {
        let resp = await axios.post('fleet/'+fleetkey+'/massconfigure', reqData);
        return resp.data;
    }

    async postMassEventsConfig(fleetkey, reqData) {
        let resp = await axios.post('fleet/'+fleetkey+'/events', reqData);
        return resp.data;
    }

    // Purge any files pending for removal (only available for admin)
    async postPurge() {
        let resp = await axios.post('purge');
        return resp.data;
    }

    async modifyProfile(modifiedProfile) {
        let resp = await axios.post('profile', modifiedProfile);
        return resp.data;
    }

    async resetMyLoginCredentials() {
        let resp = await axios.post('resetlogin');
        return resp.data;
    }

}


const restApi = new RESTService();

export function useRestApi() { return restApi; }
